<template>
  <div class="project-detail">
    <Loader :visible="loading" />
    <v-progress-circular v-if="loading" indeterminate color="purple" />
    <v-container class="ma-0 pa-0" v-else>
      <v-row justify="center" class="pa-0 ma-0" align="center">
        <span class="roboto links-projects mb-4 d-none d-sm-flex">
          <span @click="$router.push({ name: 'my-projects' })" v-if="desktop" class="mr-4 link-pointer"
            >Mis Proyectos</span
          >
          <span class="mr-4">></span>
          <span class="mr-4"
            >Postulaciones en
            <span v-if="stats.projectName">
              {{ stats.projectName.slice(0, 30)
              }}<span v-if="stats.projectName && stats.projectName.length > 40">...</span></span
            ></span
          >
          <span class="mr-4" v-if="desktop" />
        </span>
        <span class="roboto links-projects-mobile mb-4 d-flex d-sm-none">
          <span class="mr-4"
            >Postulaciones en
            <span v-if="stats.projectName">
              {{ stats.projectName.slice(0, 30) }} ...<span v-if="stats.projectName && stats.projectName.length > 40"
                >...</span
              ></span
            ></span
          >
          <span class="mr-4" v-if="desktop" />
        </span>
      </v-row>

      <v-row justify="center" class="pa-0 ma-0" align="center">
        <div class="tway-headline-2 title-desk d-none d-sm-flex">
          <span v-if="stats.projectName"> {{ `Postulaciones en ${stats.projectName}` }}</span>
        </div>

        <div class="tway-headline-2 title-mobile d-block d-sm-none text-center px-4">
          <span v-if="stats.projectName"> {{ `Comparación Propuestas ${stats.projectName}` }}</span>
        </div>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col offset-md="1" md="10" offset-lg="1" lg="10" offset-xl="2" xl="8">
          <v-alert
            color="tway-violet"
            dismissible
            outlined
            icon="mdi-information-outline"
            class="rounded-xl py-md-6"
            close-icon="mdi-close-circle-outline"
            v-if="stats.tenderRequestsCount > 0"
          >
            <span
              >Los datos a continuación muestran el estado de tu proyecto para match inteligente con
              especialistas.</span
            >
          </v-alert>
          <div class="requests-counter-container" v-if="stats.tenderRequestsCount > 0">
            <v-img class="tender-img" content-class="tender-img" :src="require('@/assets/img/postulations.svg')" />
            <div class="count-texts">
              <div class="count">
                <span> {{ stats.tenderRequestsCount }} </span>
              </div>
              <div class="count-caption">
                <span> Total de postulaciones presentadas para tu proyecto </span>
              </div>
            </div>
          </div>
          <div class="requests-counter-container empty" v-else>
            <v-img
              class="tender-img"
              :src="require('@/assets/img/empty_stats.svg')"
              max-width="169px"
              max-height="159px"
            />
            <div class="count-texts">
              <div class="count-caption" style="max-width: 17em">
                <span> Aun no hay postulaciones recibidas </span>
              </div>
            </div>
          </div>
          <br />
          <v-row>
            <v-col md="4" sm="6" cols="6">
              <t-r-detail-section
                :value="budgetAverage + ' UF'"
                label="Presupuesto promedio propuestas"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/mdi_money.svg')
                    : require('@/assets/img/stats/mdi_money_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="4" sm="6" cols="6">
              <t-r-detail-section
                :value="stats.durationAverage + ' meses'"
                label="Duración promedio propuestas"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_time.svg')
                    : require('@/assets/img/stats/carbon_time_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="4" sm="6" cols="6">
              <t-r-detail-section
                :value="stats.remainingDays == 1 ? '1 día' : stats.remainingDays + ' días'"
                label="Restantes de postulación"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_calendar.svg')
                    : require('@/assets/img/stats/carbon_calendar_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="6" sm="6" cols="6" class="d-none d-md-block">
              <t-r-detail-section
                value="test"
                :values-list="stats.expertExperiences"
                label="Experiencia especialistas"
                :horizontal="true"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_building.svg')
                    : require('@/assets/img/stats/carbon_building_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="6" sm="6" cols="6" class="d-none d-md-block">
              <t-r-detail-section
                :value="stats.category.count"
                :label="stats.category.category ? 'Especialistas en ' + stats.category.category.name : 'Especialistas'"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_user-admin.svg')
                    : require('@/assets/img/stats/carbon_user-admin_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="6" sm="6" cols="6" class="d-block d-md-none">
              <t-r-detail-section
                :value="stats.category.count"
                :label="stats.category.category ? 'Especialistas en ' + stats.category.category.name : 'Especialistas'"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_user-admin.svg')
                    : require('@/assets/img/stats/carbon_user-admin_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
            <v-col md="6" sm="12" cols="12" class="d-block d-md-none">
              <t-r-detail-section
                value="test"
                :values-list="stats.expertExperiences"
                label="Experiencia especialistas"
                :horizontal="true"
                :image="
                  hasRequests
                    ? require('@/assets/img/stats/carbon_building.svg')
                    : require('@/assets/img/stats/carbon_building_gray.svg')
                "
                :empty="!stats.tenderRequestsCount > 0"
              />
            </v-col>
          </v-row>
          <div class="mt-12 mb-6 text-center d-flex align-center justify-center flex-column-reverse flex-sm-row px-4">
            <v-btn
              v-if="stats.tenderRequestsCount > 0"
              style="
                height: 36px;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.1em;
                color: #b71c1c !important;
                border-color: transparent !important;
              "
              rounded
              class="white--text roboto mr-6 mt-12 mt-sm-0"
              outlined
              @click="modalCancel = true"
            >
              Cancelar Proyecto
            </v-btn>
            <v-btn
              large
              rounded
              color="tway-violet ml-sm-6"
              class="elevation-2 pa-4 white--text roboto"
              @click="$router.push({ name: 'my-projects' })"
            >
              Volver a mis proyectos
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="modalCancel" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center" v-if="!cancelSuccesful">
            <span class="text-center">¿Realmente quieres cancelar este proyecto?</span>
          </v-row>
          <v-row class="ma-0 pa-0" justify="center" v-if="cancelSuccesful">
            <v-icon color="#16C62E" size="75"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 modal-text" justify="center" v-if="!cancelSuccesful">
            <span>
              Al cancelar este Proyecto, se eliminarán las postulaciones que los Especialistas realizaron y el Proyecto
              pasará al estado “Listo para publicar”.
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="cancelSuccesful">
            <span> Tu Proyecto se ha cancelado exitosamente. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop && !cancelSuccesful" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalCancel = false"
            >
              VOLVER
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              small
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="cancelProject()"
            >
              SI, CANCELAR PROYECTO
            </v-btn>
          </v-col>
          <v-col v-if="desktop && cancelSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              color="tway-violet"
              class="elevation-2 mb-2 white--text roboto"
              @click="$router.push({ name: 'my-projects' })"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-row v-if="!desktop && !cancelSuccesful" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  small
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="cancelProject(cancelProjectId)"
                >
                  SI, CANCELAR PROYECTO
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="(modalCancel = false), (cancelSuccesful = false)"
                >
                  VOLVER
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-col v-if="mobile && cancelSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="$router.push({ name: 'my-projects' })"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";
import Loader from "@/components/Loader";
import TRDetailSection from "@/components/projects/TRDetailSection";

export default {
  name: "ProjectDetail",
  components: {
    Loader,
    TRDetailSection,
  },
  data() {
    return {
      stats: null,
      loading: true,
      desktop: true,
      modalCancel: false,
      cancelSuccesful: false,
    };
  },
  created() {
    CallForTenderService.getStats(this.$route.params.projectId)
      .then((res) => {
        this.stats = res;
      })
      .catch((err) => this.$log.error(err))
      .finally(() => (this.loading = false));
  },
  methods: {
    cancelProject() {
      this.loading = true;
      CallForTenderService.cancelProject(this.stats.projectId)
        .then(() => {
          this.cancelSuccesful = true;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    budgetAverage() {
      if (!this.stats || !this.stats.budgetPerRequest || this.stats.budgetPerRequest.length < 1) {
        return 0;
      }
      let total = 0;
      this.stats.budgetPerRequest.forEach((budget) => {
        total = total + parseInt(budget);
      });
      return total / this.stats.budgetPerRequest.length;
    },
    hasRequests() {
      return this.stats.tenderRequestsCount > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-desk {
  font-size: 25px;
  line-height: 32px;
}

.title-mobile {
  font-size: 22px;
  line-height: 30px;
}

#scroll-content {
  position: relative;
}
.links-projects {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Tway / Violeta */

  color: #7319d5;
}

.links-projects-mobile {
  color: #7319d5;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.link-project-red {
  color: rgb(214, 52, 52) !important;
}

.link-pointer {
  cursor: pointer;
  font-weight: bold;
}

.link-two-pointer {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Tway / Violeta */

  color: #7319d5;
  cursor: pointer;
}

.title-project {
  font-style: normal;
  font-weight: normal;
  color: #333333 !important;
  line-height: 32px;
  /* identical to box height, or 128% */

  /* Tway / Gris Oscuro */

  color: #333333;
}

.fix-height-scroll {
  height: 150px !important;
  overflow-y: scroll !important;
}

.box-title-project {
  width: 303px;
  border: 1px solid #16c62e;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height, or 136% */

  /* Tway / Violeta */
  border-radius: 4px;
  color: #7319d5;
}
.box-text-project {
  width: 303px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.box-price-project {
  width: 303px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.box-duration-project {
  width: 303px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.project-divider {
  border-bottom: 1px solid #16c62e;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  /* Tway / Gris Oscuro */

  color: #333333;
}

.requests-counter-container {
  background: linear-gradient(270deg, #7319d5 29.07%, rgba(115, 25, 213, 0.55) 100%);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  display: flex;
  padding: 21px 0px 21px 0px;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
}

.requests-counter-container.empty {
  color: #ffffff;
  background: #ffffff;
  border: 1px solid #999999;
  color: #999999;
  box-shadow: none;
}

.count-texts {
  display: flex;
  align-items: center;
  flex-basis: 50%;
  justify-content: space-around;
}

.requests-counter-container .count {
  font-size: 112.5pt;
  line-height: 112.5pt;
  padding-top: 1rem;
}

.count-caption {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  max-width: 12em;
}

.tender-img {
  max-width: 169px;
  max-height: 159px;
}

.modal-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
}

@media (max-width: 600px) {
  .count-caption {
    font-size: 14px;
    line-height: 20px;
    margin-left: 2em;
  }
  .requests-counter-container .count {
    font-size: 100px;
    line-height: 100px;
  }
  .count-texts {
    flex-basis: 70%;
  }
  .tender-img {
    max-height: 52px;
    max-width: 56px;
  }
}
</style>
