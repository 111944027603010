<template>
  <div
    :class="['tr-detail-info', horizontal ? 'horizontal' : null, empty ? 'empty' : null]"
    :style="{
      'background-image': 'url(' + image + ')',
      'background-position': 'left',
    }"
  >
    <div class="value" v-if="empty">
      <v-img v-if="horizontal" :src="require('@/assets/img/stats/items.svg')" />
      <span v-else>?</span>
    </div>
    <div class="value" v-else-if="!valuesList">
      {{ value }}
    </div>
    <div class="value" v-else style="text-align: left">
      <p v-for="val in valuesList" :key="val.name">
        {{ val.name }}
      </p>
    </div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TRDetailSection",
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    valuesList: {
      type: Array,
      required: false,
      default: null,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
    empty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
.tr-detail-info {
  border: 1px solid #7319d5;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 33px 0 33px;
}

.tr-detail-info.empty {
  border: 1px solid #999999;
  color: #999999;
}

.tr-detail-info.horizontal {
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
}

.tr-detail-info .value {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36pt;
  line-height: 36pt;
  text-align: center;
  color: #14b029;
}

.tr-detail-info.empty .value {
  color: #999999;
}

.tr-detail-info .value p {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 0.3em;
}

.tr-detail-info .label {
  flex-basis: 50%;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 18.75pt;
  text-align: center;
  color: #7319d5;
  margin-top: 9px;
}

.tr-detail-info.empty .label {
  color: #999999;
}

@media screen and (max-width: 768px) {
  .tr-detail-info {
    min-height: 165px;
  }
}

@media (max-width: 600px) {
  .tr-detail-info .value p,
  .tr-detail-info .label {
    font-size: 14px;
    line-height: 20px;
  }

  .tr-detail-info .value {
    font-size: 22px;
    line-height: 30px;
  }
}
</style>
